import {DEBUG} from './env_variables'

export const URL_API = DEBUG ? 'http://127.0.0.1:8000/' : 'https://api.vfvmolinella.it/'

export const PROJECT_TYPE = {
    IN_PROGRESS: 1,
    COMPLETED: 2
}

export const EVENT_TYPE = {
    NEXT: 1,
    OLD: 2
}

export const REQUEST_STATUS = {
    NONE: 1,
    LOADING: 2,
    SUCCESS: 3,
    ERROR: 4
}
