<template lang="pug">
    div
        .intro-container
            .img-container
                img(src="https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/mixed/new_background.png")
            .text-container
                .title VIGILI DEL FUOCO VOLONTARI MOLINELLA
                .subtitle.red Dipartimento dei Vigili del Fuoco Volontari, del Soccorso Pubblico e della Difesa Civile
            .down-arrow
                .arrow

        .content-panel
            .first-section
                .call-card
                    .left-section
                        .call-icon
                            .call
                    .right-section
                        .subtitle Per richieste d'intervento chiamare il
                        .big-text 115

                // TODO: Restore weather widget with new APIs
                //.weather-panel
                    .weather-card(v-if="!forecastLoading")
                        .upper-section
                            .image-panel
                                img(src="https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/mixed/molinella.jpg")
                            .colored-cover
                            .text-panel
                                .left
                                    .left-up
                                        .name molinella
                                        .weather-text Oggi
                                    .temp {{forecast.now.temperature}}°
                                    .today-min-max
                                        .max {{forecast.today.max}}°
                                        .min {{forecast.today.min}}°
                                .right
                                    div(:class="forecast.now.icon")
                        .under-section
                            .little-section(id="one")
                                .mini-weather-icon
                                    div(:class="forecast.tomorrow.icon")
                                .day {{getDayName(1)}}
                                .min-max-container
                                    .max {{forecast.tomorrow.max}}°
                                    .min {{forecast.tomorrow.min}}°
                            .little-section(id="two")
                                .mini-weather-icon
                                    div(:class="forecast.afterTomorrow.icon")
                                .day {{getDayName(2)}}
                                .min-max-container
                                    .max {{forecast.afterTomorrow.max}}°
                                    .min {{forecast.afterTomorrow.min}}°
                            .little-section(id="three")
                                .mini-weather-icon
                                    div(:class="forecast.dayFour.icon")
                                .day {{getDayName(3)}}
                                .min-max-container
                                    .max {{forecast.dayFour.max}}°
                                    .min {{forecast.dayFour.min}}°
                    .subtitle Informazioni meteo fornite da
                        a(href="https://darksky.net") darksky.net

            .last-section
                .subtitle Sito realizzato in collaborazione con:
                .collaboration-cards-container
                    a(href="http://goo.gl/u5ECVQ").collaboration-card
                        .logo-comune
                    a(href="/onlus").collaboration-card
                        .logo-onlus
                    a(href="http://goo.gl/q0Fru").collaboration-card
                        .logo-nazionale
                    a(href="http://goo.gl/imEJGG").collaboration-card
                        .logo-volontari
                    a(href="https://goo.gl/xAo4FW").collaboration-card
                        .logo-activepager

</template>

<script>
import axios from '../utilities/axios'
import * as moment from 'moment'

export default {
    name: 'Home',
    data() {
        return {
            forecast: null,
            forecastLoading: true
        }
    },
    mounted() {
        this.getForecastData()
    },
    methods: {
        async getForecastData() {
            try {
                const forecast = await axios.get('forecast/').then(response => response.data)

                this.forecastLoading = false

                this.forecast = {
                    now: {
                        temperature: parseInt(forecast.currently.temperature, 10),
                        icon: forecast.currently.icon
                    },
                    today: {
                        min: parseInt(forecast.daily.data[0].temperatureMin, 10),
                        max: parseInt(forecast.daily.data[0].temperatureMax, 10),
                        icon: forecast.daily.data[0].icon
                    },
                    tomorrow: {
                        min: parseInt(forecast.daily.data[1].temperatureMax, 10),
                        max: parseInt(forecast.daily.data[1].temperatureMin, 10),
                        icon: forecast.daily.data[1].icon
                    },
                    afterTomorrow: {
                        min: parseInt(forecast.daily.data[2].temperatureMax, 10),
                        max: parseInt(forecast.daily.data[2].temperatureMin, 10),
                        icon: forecast.daily.data[2].icon
                    },
                    dayFour: {
                        min: parseInt(forecast.daily.data[3].temperatureMax, 10),
                        max: parseInt(forecast.daily.data[3].temperatureMin, 10),
                        icon: forecast.daily.data[3].icon
                    }
                }
            } catch (e) {
                this.forecast = null
                this.forecastLoading = false
            }
        },
        getDayName(day) {
            return moment()
                .add(day, 'days')
                .format('ddd')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
    @import "../assets/global"

    .clear-day
        +weather(48px, 434px)

    .clear-night
        +weather(48px, 385px)

    .rain
        +weather(48px, 39px)

    .snow, .sleet
        +weather(48px, 286px)

    .wind
        +weather(48px, 533px)

    .fog
        +weather(48px, 88px)

    .cloudy
        +weather(48px, -9px)

    .partly-cloudy-day
        +weather(48px, 337px)

    .partly-cloudy-night
        +weather(48px, 237px)

    .hail
        +weather(48px, 137px)

    .thunderstorm
        +weather(48px, 186px)

    .tornado
        +weather(48px, 484px)

    .logo-comune
        +sprite(-90px, 1590px, 240px)
        +breakpoint("<phone")
            +sprite(-52px, 1352px, 140px)
        +breakpoint("<small-phone")
            +sprite(-33px, 1232px, 90px)

    .logo-onlus
        +sprite(-90px, 1502px, 240px)
        +breakpoint("<phone")
            +sprite(-53px, 1301px, 140px)
        +breakpoint("<small-phone")
            +sprite(-33px, 1199px, 90px)

    .logo-volontari
        +sprite(-2px, 1679px, 240px)
        +breakpoint("<phone")
            +sprite(0, 1405px, 140px)
        +breakpoint("<small-phone")
            +sprite(1px, 1266px, 90px)

    .logo-nazionale
        +sprite(-1px, 1590px, 240px)
        +breakpoint("<phone")
            +sprite(0, 1353px, 140px)
        +breakpoint("<small-phone")
            +sprite(0, 1232px, 90px)

    .logo-activepager
        +sprite(-90px, 1679px, 240px)
        +breakpoint("<phone")
            +sprite(-52px, 1404px, 140px)
        +breakpoint("<small-phone")
            +sprite(-33px, 1266px, 90px)

    .main-container
        padding-top: 0 !important

    a
        color: inherit

    .subtitle
        > a
            margin-left: .5em

    .min, .max
        +flexbox(flex-start, center, row, nowrap)
        position: relative
        font-size: .8em
        font-weight: bold

    .min:before, .max:before
        content: ''
        position: absolute
        left: -10px
        height: 6px
        width: 6px
        border-radius: 50%

    .min:before
        background-color: #1E88E5

    .max:before
        background-color: #F44336

    .intro-container
        +flexbox(center, center, column, nowrap)
        position: relative
        width: 100%
        height: 100vh
        overflow: hidden

        .img-container
            +flexbox(center, center, row, nowrap)
            position: relative
            height: 100%
            width: 100%
            overflow: hidden
            filter: brightness(60%)

            img
                position: relative
                height: 170%


        .text-container
            +flexbox(center, center, column, nowrap)
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            z-index: 1
            color: #fff
            text-align: center
            padding: 1em
            +breakpoint("<tablet")
                font-size: 14px
            +breakpoint("<phone")
                font-size: 11px
            +breakpoint("<small-phone")
                font-size: 13px

            .title
                font-size: 3em
                letter-spacing: .06em
                font-weight: bold
                margin-bottom: .3em

            .red
                color: #F44336 !important
                letter-spacing: .14em

        &:after
            position: absolute
            bottom: -1em
            left: -1em
            right: -1em
            content: ''
            background-image: url(../images/cut.svg)
            background-repeat: no-repeat
            background-position: center bottom
            height: 100%
            +breakpoint("<tablet")
                display: none

        .down-arrow
            +flexbox(center, center, column, nowrap)
            z-index: 3
            position: absolute
            top: 95vh
            height: 32px
            width: 32px
            border-radius: 50%
            background: #fff
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)
            transform: scale(1.3)
            transition: transform .25s ease
            +breakpoint("<phone")
                top: 90vh

            .arrow
                +icon(24px, 528px)
                background: #1E88E5
                position: relative
                top: 1px

            &:hover
                transform: scale(1.4)

    .content-panel
        +flexbox(center, center, column, nowrap)
        position: relative
        padding: 5em 0

        .first-section
            +flexbox(center, center, row, wrap)

            .call-card, .weather-card
                margin: 1em 2em
                border-radius: .3em
                border: .05em solid rgba(0, 0, 0, .10)
                +breakpoint("<phone")
                    margin: 2em 0

            .call-card
                +flexbox(center, center, row, nowrap)
                font-size: 25px
                +breakpoint("<tablet")
                    font-size: 20px
                +breakpoint("<phone")
                    font-size: 16px

                .left-section
                    +flexbox(center, center, column, nowrap)
                    padding: 1em
                    height: 5em

                    .call-icon
                        +flexbox(center, center, column, nowrap)
                        border-radius: 50%
                        background: #F44336
                        padding: .5em

                        .call
                            +icon(24px, 481px)
                            background: #fff

                .right-section
                    +flexbox(center, flex-start, column, nowrap)
                    padding: .2em 1em .8em 0

                    .big-text
                        margin-top: .2em
                        font-size: 2.5em
                        font-weight: bold

            .weather-panel
                +flexbox(center, center, column, nowrap)
                margin-bottom: 2em

                .weather-card
                    +flexbox(flex-start, center, column, nowrap)
                    height: 20em
                    width: 20em
                    margin-bottom: 0
                    +breakpoint("<small-phone")
                        border-radius: 0

                    .upper-section
                        +flexbox(center, center, row, nowrap)
                        position: relative
                        width: 20em
                        height: 11em

                        .colored-cover, .image-panel
                            border-radius: .3em .3em 0 0
                            +breakpoint("<small-phone")
                                border-radius: 0

                        .colored-cover
                            position: absolute
                            top: 0
                            bottom: 0
                            left: 0
                            right: 0
                            background-color: rgba(30, 136, 229, .7)

                        .image-panel
                            +flexbox(center, center, column, nowrap)
                            top: 0
                            left: 0
                            right: 0
                            bottom: 0
                            position: absolute
                            overflow: hidden

                            img
                                position: relative
                                width: 100%

                        .text-panel
                            +flexbox(space-between, center, row, nowrap)
                            top: 0
                            left: 0
                            right: 0
                            bottom: 0
                            position: absolute
                            color: #fff

                            .left
                                padding-left: 1.8em

                                .left-up
                                    +flexbox(center, flex-start, column, nowrap)
                                    margin-bottom: 2em

                                    .name
                                        font-size: 1.6em
                                        text-transform: capitalize
                                        margin-bottom: .5em
                                        position: relative
                                        left: -2px

                                        &:after
                                            position: absolute
                                            content: ''
                                            height: .08em
                                            width: .8em
                                            display: block
                                            background: #fff
                                            bottom: -.2em
                                            left: 2px

                                    .weather-text
                                        text-transform: uppercase
                                        font-weight: bold
                                        font-size: .7em
                                        letter-spacing: .06em

                                .temp
                                    font-size: 2em

                                .today-min-max
                                    +flexbox(space-around, center, row, nowrap)
                                    width: 4em

                            .right
                                margin-top: 4.4em
                                padding-right: 2em
                                div
                                    background-color: #fff

                    .under-section
                        +flexbox(center, center, row, nowrap)
                        height: 9em
                        position: relative
                        width: 100%

                        .little-section
                            +flexbox(center, center, column, nowrap)
                            position: relative
                            width: calc(100% / 3)
                            height: 100%

                            .mini-weather-icon
                                margin-bottom: .7em

                            .day
                                font-size: .8em
                                text-transform: uppercase
                                font-weight: bold
                                opacity: .6

                            .min-max-container
                                +flexbox(center, flex-start, column, nowrap)
                                margin: .5em 0 0

        .last-section
            +flexbox(center, center, column, nowrap)
            margin: 4em 0

            .collaboration-cards-container
                +flexbox(center, center, row, wrap)
                +breakpoint("<phone")
                    margin: 1em 0 2em
                +breakpoint("<small-phone")
                    margin: 1em 0

                .collaboration-card
                    margin: 2em
                    filter: grayscale(100%) opacity(.5)
                    transition: filter .3s ease
                    +breakpoint("<phone")
                        margin: .8em

                    .logo-comune, .logo-nazionale, .logo-onlus, .logo-volontari, .logo-activepager
                        height: 5em
                        width: 5em
                        +breakpoint("<phone")
                            height: 3em
                            width: 3em
                        +breakpoint("<small-phone")
                            height: 2em
                            width: 2em

                    &:hover
                        filter: grayscale(0%) opacity(1)

</style>
