<template lang="pug">
    div(v-if="!error")
        .container
            .loader-container(v-if="load")
                loader
            .flex-panel(v-else)
                .back-arrow(@click="$router.push({ name: 'Eventi'})")
                    .arrow
                .upper-section
                    .date
                        h2.day {{event.date.format('DD')}}
                        h2.month {{event.date.format('MMM')}}
                    .left
                        h2.time {{event.date.format('HH:mm')}}
                        h2.place Piazza Massarenti Molinella (Bo)
                .main-section
                    .element-title {{event.name}}
                    .image-container
                        img(:src="event.image")
                    .subtitle {{event.description}}
                .actions-section
                    .sub-action
                        input#url-input(type="text")
                        span(@click="copyUrl()")
                            .copy-ico
                        .action
                            transition(name="component-fade" mode="out-in")
                                .last(v-if="copied") Link copiato
    evento-non-trovato(v-else)
</template>

<script>
import axios from '../utilities/axios'
import * as moment from 'moment'
import EventoNonTrovato from './EventoNonTrovato'

export default {
    name: 'Evento',
    data() {
        return {
            load: true,
            event: null,
            error: false,
            copied: false
        }
    },
    mounted() {
        this.getEvent()
    },
    methods: {
        copyUrl() {
            document.getElementById('url-input').value = window.location.href
            const urlInput = document.getElementById('url-input')
            urlInput.select()
            document.execCommand('copy')
            this.copied = true
            setTimeout(() => {
                this.copied = false
            }, 1500)
        },
        async getEvent() {
            try {
                this.load = true
                const response = await axios.get(`event/${this.$route.params.id}/`)
                this.load = false
                const event = response.data
                this.event = {
                    ...event,
                    date: moment(event.date, 'YYYYMMDD HH:mm')
                }
            } catch (e) {
                this.error = true
            }
        }
    },
    components: {
        EventoNonTrovato
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
    @import "../assets/global"

    .component-fade-enter-active, .component-fade-leave-active
        transition: opacity .3s ease

    .component-fade-enter, .component-fade-leave-to
        opacity: 0

    .copy-ico
        +icon(24px, 601px)

    .flex-panel
        +flexbox(center, center, column, nowrap)
        padding-bottom: 3em
        position: relative
        +breakpoint('<tablet')
            width: 85%

        .back-arrow
            cursor: pointer
            position: absolute
            left: 0
            top: 4em
            +breakpoint('<tablet')
                top: .5em

        .upper-section
            +flexbox(center, center, row, nowrap)
            margin: 3em 0
            +breakpoint('<tablet')
                margin-bottom: 0

            .date
                +flexbox(center, center, column, nowrap)
                font-weight: bold
                background-color: rgba(30, 136, 229, .07)
                color: #1E88E5
                width: fit-content
                padding: .9em 1.05em .8em
                border-radius: 50%

                .day
                    font-size: 1.6em
                    line-height: .8em

                .month
                    font-size: 1.1em

            .left
                +flexbox(center, flex-start, column, nowrap)
                margin-left: 2em
                font-weight: bold
                color: rgba(0, 0, 0, .65)
                font-size: .88em

                > *
                    line-height: 1.1em
                    letter-spacing: .04em

        .main-section
            +flexbox(center, center, column, nowrap)
            max-width: 50em
            text-align: center
            font-size: 17px
            +breakpoint('<tablet')
                align-items: flex-start

            .image-container
                +flexbox(center, center, column, nowrap)
                margin: 2em 0
                position: relative
                width: 60%
                border-radius: .6em
                overflow: hidden
                +breakpoint('<tablet')
                    width: 100%

                img
                    position: relative
                    width: 100%

        .actions-section
            +flexbox(flex-end, center, row, nowrap)
            position: relative
            width: 100%
            margin: 2em 0
            padding: .5em .8em 0
            border-top: .04em solid rgba(0, 0, 0, .10)

            .sub-action
                +flexbox(center, center, row, nowrap)
                input
                    border: none
                    border-bottom: .04em solid rgba(0, 0, 0, .10)
                    border-radius: 0
                    font-family: 'Nunito', sans-serif
                span
                    margin-left: 1em
                    cursor: pointer
                .action
                    position: absolute
                    top: 0
                    right: 16em
                    padding: .9em 0
                    border-radius: .2em
                    font-weight: bold
                    font-size: .9em
                    letter-spacing: .03em
                    color: rgba(0, 0, 0, .7)
                    transition: color .25s ease
                    .last
                        +flexbox(center, center, row, nowrap)
                        color: #1E88E5
</style>
