<template lang="pug">
    .container
        h1.title La flotta
        .loader-container(v-if="load")
            loader
        template(v-else)
            .flex-container
                card-automezzo(:item="vehicle" v-for="vehicle in activeVehicles" v-bind:key="vehicle.id")
            h1.title Automezzi non più operativi presso il Distaccamento di Molinella
            .flex-container
                card-automezzo(:item="vehicle" v-for="vehicle in inactiveVehicles" v-bind:key="vehicle.id")

</template>

<script>
import axios from '../utilities/axios'
import CardAutomezzo from './CardAutomezzo.vue'

export default {
    name: 'Automezzi',
    data() {
        return {
            load: false,
            vehicles: []
        }
    },
    mounted() {
        this.getVehicles()
    },
    computed: {
        activeVehicles() {
            return this.vehicles.filter(vehicle => vehicle.active)
        },
        inactiveVehicles() {
            return this.vehicles.filter(vehicle => !vehicle.active)
        }
    },
    methods: {
        async getVehicles() {
            this.load = true
            const response = await axios.get('vehicle/')
            this.load = false
            this.vehicles = response.data
        }
    },
    components: {
        CardAutomezzo
    }
}
</script>

<style lang="sass" scoped>
    @import "../assets/global"

    .flex-container
        +flexbox(center, flex-start, row, wrap)
</style>

