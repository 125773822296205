<template lang="pug">
    div(v-if="$route.path !== '/antonino'")
        nav.navbar(:class="{'open-nav': open}")
            ul
                router-link(v-for="route in first_route"
                tag="li"
                :to="route.route" :key='route.title'
                :class='{"active": $route.path === route.route}' @click.native="closeNavbar()")
                    a
                        div.ico(:class='route.icon') 
                        label {{route.title}}
            router-link(to="/" tag="span" class="top-img-container" @click.native="closeNavbar()")
                .img-container
                    a
                        .logo
            ul
                router-link(v-for="route in second_route"
                tag="li"
                :to="route.route" :key='route.title'
                :class='{"active": $route.path === route.route}' @click.native="closeNavbar()")
                    a
                        div.ico(:class='route.icon') 
                        label {{route.title}}
        .mobile-navbar
            .material-icons(@click="openNavbar()") menu
            .site-title vfvmolinella
        .dark-cover(:class="{'appear': open}" @click="closeNavbar()")
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            open: false,
            first_route: [
                {
                    title: 'Eventi',
                    icon: 'event',
                    route: '/eventi'
                },
                {
                    title: 'Automezzi',
                    icon: 'car',
                    route: '/automezzi'
                },
                {
                    title: 'Volontari',
                    icon: 'group',
                    route: '/volontari'
                },
                {
                    title: 'Home',
                    icon: 'home',
                    route: '/'
                }
            ],
            second_route: [
                {
                    title: 'ODV',
                    icon: 'help',
                    route: '/onlus'
                },
                {
                    title: 'Cosa facciamo',
                    icon: 'jobs',
                    route: '/cosa_facciamo'
                },
                {
                    title: 'Foto',
                    icon: 'photo',
                    route: '/album'
                },
                {
                    title: 'Contatti',
                    icon: 'person',
                    route: '/contatti'
                }
            ]
        }
    },
    methods: {
        openNavbar() {
            this.open = true
            this.toggleContainerClass('addClass', 'navbar-open')
        },
        closeNavbar() {
            this.open = false
            this.toggleContainerClass('removeClass', 'navbar-open')
        },
        toggleContainerClass(addRemoveClass, className) {
            const page = document.body
            if (addRemoveClass === 'addClass') page.classList.add(className)
            else page.classList.remove(className)
        }
    }
}
</script>

<style lang="sass" scoped>
    @import "./../assets/global"
    $icon-size: 24px

    .home
        +icon($icon-size, -2px)
    .group
        +icon($icon-size, 22px)
    .car
        +icon($icon-size, 45px)
    .event
        +icon($icon-size, 70px)
    .help
        +icon($icon-size, 94px)
    .jobs
        +icon($icon-size, 118px)
    .photo
        +icon($icon-size, 166px)
    .person
        +icon($icon-size, 142px)

    nav, .mobile-navbar
        position: fixed
        height: 3.5em
        background-color: #1E88E5
        color: #fff
        top: 0
        right: 0
        left: 0
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)
    nav
        +flexbox(center, center, row, nowrap)
        flex-shrink: 0
        z-index: 6
        padding: 0
        text-transform: capitalize
        +breakpoint('<tablet')
            overflow-y: auto
            flex-direction: column
            left: -16.1em
            right: initial
            height: 100%
            width: 16em
            background-color: #fff
            color: rgba(0,0,0,.9)
            justify-content: flex-start
            padding-top: 8em
            transition: left .3s ease
            &.open-nav
                left: 0
                +breakpoint('phone-landscape')
                    position: absolute
                    height: auto
                    width: 100%
                    overflow-y: scroll
                    padding-bottom: 3em

        ul:nth-child(1)
            width: 100%
            flex-direction: row-reverse
            justify-content: right
            +breakpoint('<tablet')
                flex-direction: column-reverse
                justify-content: flex-end

        ul:last-child
            width: 100%
            justify-content: left
            +breakpoint('<tablet')
                flex-direction: column
                margin-bottom: 2em
        ul
            +flexbox(center, center, row, nowrap)
            flex-shrink: 0
            height: 100%
            +breakpoint('<tablet')
                height: auto

            li
                display: block
                +breakpoint('<tablet')
                    width: 90%
                    margin: .25em auto
                    +flexbox(flex-start, center, row, nowrap)
                a   
                    
                    position: relative
                    width: 100%
                    letter-spacing: .06em
                    text-decoration: none
                    color: inherit
                    font-weight: 500
                    margin: 0 .35em
                    padding: 0 .6em
                    transition: background-color 0.3s ease
                    +breakpoint('<tablet')
                        font-weight: bold
                        +flexbox(flex-start, center, row, nowrap)
                        border-radius: .25em
                        padding: .65em .6em
                        &:hover
                            color: #1E88E5
                            background-color: rgba(30, 136, 229, .2)
                            .ico
                                background: rgb(30, 136, 229)
                    > *
                        cursor: pointer
                        color: inherit
                    .ico
                        display: none
                        margin-right: 1.2em
                        +breakpoint('<tablet')
                            display: block
            .active
                +breakpoint('<tablet')
                    color: #1E88E5
                    pointer-events: none
                    a
                        .ico
                            background: rgb(30, 136, 229)

            .active:after
                min-height: .2em
                border-radius: .1em .1em 0 0
                background-color: #fff
                content: ''
                display: block
                position: relative
                width: calc(100% - 2.2em)
                margin: -.2em auto 0
                top: 1.24em
                +breakpoint('<tablet')
                    display: none

        .top-img-container
            position: relative
            top: 0.5em
            +breakpoint('<tablet')
                position: absolute
                top: 1.5em
            >.img-container
                width: 100%
                height: 5em
                width: 5em
                margin: 0 1.2em
                background-color: #fff
                border-radius: 3em
                box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)
                +breakpoint('<tablet')
                    margin: 0 auto
                .logo
                    +sprite(9px, 1376px, 182px)
                    position: relative
                    height: 100%
    .mobile-navbar
        display: none
        +breakpoint('<tablet')
            z-index: 5
            padding: 0 2em
            +flexbox(flex-start, center, row, nowrap)
            .material-icons
                margin-right: 1em
                cursor: pointer
            .site-title
                letter-spacing: .08em
                font-weight: bold

    .dark-cover
        z-index: -1
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background-color: rgba(0, 0, 0, 0.35)
        opacity: 0
        transition: opacity .3s linear
        &.appear
            opacity: 0
            z-index: -1
        +breakpoint('<tablet')
            &.appear
                opacity: 1
                z-index: 4

</style>