import Home from '@/views/Home.vue'
import Automezzi from '@/views/Automezzi.vue'
import Volontari from '@/views/Volontari.vue'
import Eventi from '@/views/Eventi.vue'
import MainContainer from '@/components/MainContainer.vue'
import Page404 from '@/views/Page404.vue'
import Onlus from '@/views/Onlus.vue'
import CosaFacciamo from '@/views/CosaFacciamo.vue'
import Formazione from '@/views/Formazione.vue'
import Foto from '@/views/Foto.vue'
import Contatti from '@/views/Contatti.vue'
import Evento from '@/views/Evento.vue'
import Cinquepermille from '@/views/Cinquepermille.vue'
import Tabella from '@/views/Tabella.vue'
import Album from '@/views/Album.vue'
import Antonino from '@/views/Antonino.vue'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',

    routes: [
        {
            path: '/completato',
            name: 'newStore',
            component: MainContainer,
            meta: {
                title: ''
            }
        },
        {
            path: '/',
            component: MainContainer,
            children: [
                {
                    path: '/',
                    name: 'Home',
                    component: Home,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/automezzi',
                    name: 'Automezzi',
                    component: Automezzi,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/volontari',
                    name: 'Volontari',
                    component: Volontari,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/eventi',
                    name: 'Eventi',
                    component: Eventi,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/onlus',
                    name: 'Onlus',
                    component: Onlus,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/formazione',
                    name: 'Formazione',
                    component: Formazione,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/cosa_facciamo',
                    name: 'CosaFacciamo',
                    component: CosaFacciamo,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/album',
                    name: 'Foto',
                    component: Foto,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/contatti',
                    name: 'Contatti',
                    component: Contatti,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/album/:id',
                    name: 'Album',
                    component: Album,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/onlus/donazioni_pubbliche',
                    name: 'Tabella',
                    component: Tabella,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/onlus/5x1000',
                    name: 'Cinquepermille',
                    component: Cinquepermille,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/eventi/:id',
                    name: 'Evento',
                    component: Evento,
                    meta: {
                        title: ''
                    }
                },
                {
                    path: '/antonino',
                    name: 'Antonino',
                    component: Antonino,
                    meta: {
                        title: ''
                    }
                }
            ]
        },
        {
            path: '*',
            component: Page404
        }
    ]
})
