<template lang="pug">
    .projects-container
        .section-buttons
            span(@click="selectType(PROJECT_TYPE.IN_PROGRESS)" :class="{'active': projectsType === PROJECT_TYPE.IN_PROGRESS}") Progetti da realizzare
            span(@click="selectType(PROJECT_TYPE.COMPLETED)" :class="{'active': projectsType === PROJECT_TYPE.COMPLETED}") Progetti realizzati
        .loader-container(v-if="load")
            loader
        .flex-container(v-else)
            .project-card(v-for="project in selectedProjects")
                .cover-container(@click="selectProject(project)")
                    img(:src="getCover(project.images)")
                .text-container
                    .element-title.shorted {{project.name}}
                    .subtitle {{getDonationPriceString(project)}}

        modal-progetto(:project="selectedProject" @close="closeModal()")
</template>

<script>
import axios from '../utilities/axios'
import ModalProgetto from './ModalProgetto'
import {PROJECT_TYPE} from '../costants/constants'

export default {
    name: 'Projects',
    components: {
        ModalProgetto
    },
    data() {
        return {
            projects: [],
            load: false,
            selectedProject: null,
            projectsType: PROJECT_TYPE.IN_PROGRESS,
            PROJECT_TYPE
        }
    },
    mounted() {
        this.getProject()
    },
    computed: {
        // Prende i progetti in svolgimento
        projectsInProgress() {
            return this.projects.filter(project => !project.completed)
        },
        // Prende i progetti completati
        completedProjects() {
            return this.projects.filter(project => project.completed)
        },
        selectedProjects() {
            if (this.projectsType === PROJECT_TYPE.COMPLETED) return this.completedProjects

            return this.projectsInProgress
        }
    },
    methods: {
        async getProject() {
            this.load = true
            const response = await axios.get('project/')
            this.load = false
            this.projects = response.data
        },
        // Prende la cover del progetto
        getCover(images) {
            const image = images.find(image => image.cover)

            return image === undefined ? images[0].image : image.image
        },
        selectProject(project) {
            this.selectedProject = project
        },
        closeModal() {
            this.selectedProject = null
        },
        selectType(type) {
            this.projectsType = type
        },
        getDonationPriceString(project) {
            if (project.completed) return `Sono stati donati € ${Number(project.price === null ? '' : project.price.toFixed(2)).toLocaleString('it')}`

            return `Importo richiesto: € ${Number(project.price === null ? '' : project.price.toFixed(2)).toLocaleString('it')}`
        }
    }
}
</script>

<style lang="sass" scoped>
@import "./../assets/global"
.projects-container
    +flexbox(center, center, column, nowrap)
    margin: 3em 0 1em
    width: 80%
    +breakpoint("<phone")
        width: 100%
    +breakpoint('<small-phone')
        font-size: 14px

    .section-buttons
        +flexbox(center, center, row, nowrap)
        margin: 4em 0
        padding-bottom: 1em
        position: relative
        width: 60%
        border-bottom: 1px solid rgba(0, 0, 0, .07)
        +breakpoint('<tablet')
            width: 100%

        span
            cursor: pointer
            background: none
            border: none
            margin: 0 2em
            letter-spacing: .08em
            font-size: .9em
            font-weight: bold
            font-family: inherit
            color: rgba(0, 0, 0, .45)
            transition: color 0.4s linear
            +breakpoint('<tablet')
                +flexbox(center, center, column, wrap)
                text-align: center
            +breakpoint('<phone')
                margin: 0 1em
            &:hover
                color: #1E88E5
        .active
            color: #1E88E5

    .flex-container
        .project-card
            +flexbox(center, center, column, nowrap)
            width: 16em
            margin: 3em
            overflow: hidden
            +breakpoint("<phone")
                margin: 3em 0
            +breakpoint('<small-phone')
                font-size: 8px
                margin: 2em 1em
            .cover-container
                height: 16em
                +breakpoint('<small-phone')
                    border: .05em solid rgba(0,0,0,.10)
                img
                    position: relative
                    height: 100%
            .text-container
                +flexbox(center, flex-start, column, nowrap)
                position: relative
                width: 100%
</style>
