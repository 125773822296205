import originalAxios from 'axios'
import { URL_API } from '../costants/constants'
import { toCamelCase } from './functions'

const axios = originalAxios.create({
    baseURL: `${URL_API}website/`
})

// Trasforma la risposta
axios.interceptors.response.use(
    response => {
        response.data = toCamelCase(response.data)
        // Do something with response data
        return response
    },
    error => {
        // Do something with response error
        return Promise.reject(error)
    }
)

export default axios
