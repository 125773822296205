<template lang="pug">
    .page(id="main_page")
        nav-bar
        router-view.main-container
        Footer
</template>

<script>
import NavBar from './NavBar'
import Footer from './Footer'

export default {
    name: 'MainContainer',
    components: {NavBar, Footer}
}
</script>

<style lang="sass" scoped>
    @import "../assets/global"
    
    .main-container
        padding-top: 5em
</style>