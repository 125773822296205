<template lang="pug">
    .container
        h1.title Galleria fotografica

        .loader-container(v-if="load")
            loader
        template(v-else)
            .flex-container
                .album-card(v-for="album in albums")
                    .cover-container
                        img(:src="album.cover" @click="openAlbum(album.id)")
                    .text-container
                        .element-title.shorted {{album.name}}
                        .subtitle {{getImagesLength(album.albumImage)}} Foto

</template>

<script>
import axios from '../utilities/axios'

export default {
    name: 'Foto',
    data() {
        return {
            load: true,
            albums: null
        }
    },
    mounted() {
        this.getAlbums()
    },
    methods: {
        async getAlbums() {
            this.load = true
            const response = await axios.get('album/')
            this.albums = response.data
            this.load = false
        },
        getImagesLength(images) {
            return images.length
        },
        openAlbum(id) {
            this.$router.push({name: 'Album', params: {id}})
        }
    }
}
</script>

<style lang="sass" scoped>
    @import "../assets/global"
    .flex-container
        max-width: 80%
        margin-bottom: 4em
        +breakpoint('<tablet')
            max-width: 100%
        +breakpoint('<phone')
            font-size: 11px
        +breakpoint('<small-phone')
            font-size: 8px

    .album-card
        +flexbox(center, center, column, nowrap)
        width: 13em
        margin: 4em 3em 0
        +breakpoint('<phone')
            margin: 2em
        +breakpoint('<small-phone')
            margin: 1em 2.5em

        .cover-container
            +flexbox(center, center, column, nowrap)
            cursor: pointer
            position: relative
            max-width: 100%
            height: 13em
            border-radius: .4em
            overflow: hidden
            filter: brightness(100%)
            transition: filter .2s ease

            &:hover
                filter: brightness(80%)

            img
                position: relative
                height: 150%

        .text-container
            +flexbox(center, flex-start, column, nowrap)
            position: relative
            width: 100%

        .subtitle
            +breakpoint('<small-phone')
                font-size: 1em

</style>

