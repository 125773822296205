<template lang="pug">
    .flex-panel
        .image-container
            .sky
                .text-container
                    .left-section
                        span Error 404
                    .right-section
                        .subtitle la pagina ricercata non è presente sul server
                        a(href="/") Torna alla home
                .hydrant
            .base
</template>

<script>
export default {
    name: 'Page404'
}
</script>

<style lang="sass" scoped>
    @import "../assets/global"

    .flex-panel
        +flexbox(center, center, column, nowrap)
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        .image-container
            +flexbox(center, center, column, nowrap)
            .sky
                position: absolute
                top: 0
                bottom: 0
                left: 0
                right: 0
                background: #2980B9
                background: linear-gradient(to left bottom, #FFFFFF, #6DD5FA, #2980B9)
                +flexbox(center, center, column, nowrap)
                +breakpoint('<phone')
                    justify-content: flex-start
                .hydrant
                    height: 13em
                    width: 13em
                    position: absolute
                    bottom: 18%
                    right: 10%
                    +sprite(center, 1217px, 208px)
                    +breakpoint("<phone")
                        +sprite(center, 1168px, 150px)
                        height: 9em
                        width: 9em
                .text-container
                    +flexbox(center, center, row, nowrap)
                    font-size: 16px
                    +breakpoint('<phone')
                        font-size: 14px
                        margin-top: 50%
                    .left-section
                        +flexbox(center, center, row, nowrap)
                        font-weight: bold
                        text-transform: uppercase
                        font-size: 3em
                        padding-right: .4em
                        opacity: .6
                        text-shadow: -1px 1px 2px rgba(150, 150, 150, 1)
                        text-align: right
                    .right-section 
                        +flexbox(center, flex-start, column, nowrap)
                        .subtitle
                            padding-top: 0
                            padding-bottom: .5em
                        a
                            font-size: .85em
                            font-weight: bold
                            letter-spacing: .04em
                            color: #000
            .base
                position: absolute
                bottom: 0
                left: 0
                right: 0
                height: 18%
                background: #322A26
</style>