<template lang="pug">
    .container
        h1.title Come contattarci

        .flex-form
            .title-wrapper
                h1 Per richieste d'intervento chiamare il 115
                .subtitle Gli altri numeri di telefono devono essere utilizzati esclusivamente al fine di ottenere chiarimenti e maggiori informazioni sul distaccamento.
            .card-wrapper
                .left-section
                    .section-title Lasciaci un messaggio
                    form(v-on:submit.prevent="sendMail()")
                        .upper-row
                            .group
                                input(type="text" v-model='email' required)
                                span.highlight
                                span.bar
                                label Email
                            .group
                                input(type="text" v-model='name' required)
                                span.highlight
                                span.bar
                                label Nome
                        .group
                            textarea(type="textarea" v-model='message' rows="3" required)
                            span.highlight
                            span.bar
                            label Messaggio
                        .lower-section
                            button Invia messaggio
                            transition(name="component-fade" mode="out-in")
                                .send(v-if="request === 3") Messaggio inviato
                            
                .right-section
                    .titles-selection
                        button.section-title(@click="toggleSections()" :class="{'active': showInfo}") Informazioni di contatto
                        button.section-title(@click="toggleSections()" :class="{'active': showMap}") Vedi mappa
                    transition(name="component-fade" mode="out-in")
                        div(v-if="showInfo")
                            ul.first-list
                                li
                                    .ico.place
                                    span Via Aldo Moro 26, Molinella BO
                                li
                                    .ico.mail
                                    span vigilfuocomolinella@gmail.com
                                li
                                    .ico.phone
                                    span 051 - 880889
                                li
                                    .ico.cellphone
                                    span 348 - 5253518
                            ul.social-bar
                                li
                                    a(href="https://www.instagram.com/vfvmolinella/" target="_blank")
                                        .instagram
                                li
                                    a(href="https://www.facebook.com/vvfmolinella/" target="_blank")
                                        .facebook
                                li
                                    a(href="https://www.tiktok.com/@vfvmolinella" target="_blank")
                                        .tiktok
                    transition(name="component-fade" mode="out-in")
                        .map(v-show="showMap")
                            iframe.map-content(width="400" height="400" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJP2SkibY7fkcR1ip_Ml1VxT4&key=AIzaSyAKONPSorpENNq8ClNGsYKe_Vg_6OCgjiQ"
                            allowfullscreen)
            .small-text
                .subtitle Made by 
                    span.credits-name Massbit
</template>

<script>
import axios from 'axios'
import Lottie from 'vue-lottie'
import LottieCopy from 'vue-lottie'
import loaderData from '../assets/single-wave-loader.json'
import doneData from '../assets/done.json'
import {REQUEST_STATUS, URL_API} from '../costants/constants'

export default {
    name: 'Contatti',
    data() {
        return {
            showInfo: true,
            showMap: false,
            name: '',
            email: '',
            message: '',
            request: REQUEST_STATUS.NONE,
            defaultLoaderOptions: {
                animationData: loaderData,
                autoplay: true,
                loop: true
            },
            defaultDoneOptions: {
                animationData: doneData,
                autoplay: true,
                loop: false
            }
        }
    },
    methods: {
        toggleSections() {
            this.showInfo = !this.showInfo
            this.showMap = !this.showMap
        },
        async sendMail() {
            this.request = REQUEST_STATUS.LOADING
            try {
                await axios.post(`${URL_API}notifications/contact_mail/`, {
                    name: this.name,
                    email: this.email,
                    message: this.message
                })
                this.request = REQUEST_STATUS.SUCCESS
            } catch (e) {
                this.request = REQUEST_STATUS.ERROR
            }
            setTimeout(() => {
                this.request = 1
            }, 1500)
        }
    },
    components: {
        Lottie,
        LottieCopy
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
    @import "../assets/global"
    $input-length: 17em
    $icon-size: 24px

    .subtitle
        +breakpoint('<phone')
            font-size: .9em

    .component-fade-enter-active, .component-fade-leave-active
        transition: opacity .3s ease

    .component-fade-enter, .component-fade-leave-to
        opacity: 0

    .place
        +icon($icon-size, 240px)
        +breakpoint('<phone')
            +icon($icon-size/1.5, 256px)

    .mail
        +icon($icon-size, 191px)
        +breakpoint('<phone')
            +icon($icon-size/1.5, 220px)

    .phone
        +icon($icon-size, 481px)
        +breakpoint('<phone')
            +icon($icon-size/1.5, 430px)

    .cellphone
        +icon($icon-size, 215px)
        +breakpoint('<phone')
            +icon($icon-size/1.5, 238px)

    .instagram
        +icon($icon-size*.8, 268px)

    .facebook
        +icon($icon-size*.8, 289px)

    .youtube
        +icon($icon-size*.8, 309px)

    .tiktok
        +icon($icon-size*.8, 581px)

    button, textarea
        font-family: 'Nunito', sans-serif

    .titles-selection,
    .upper-row
        +flexbox(space-between, center, row, nowrap)

    .flex-form
        +flexbox(center, center, column, nowrap)
        +breakpoint('<phone')
            font-size: 10px
        +breakpoint('<small-phone')
            font-size: 9px

        .title-wrapper
            margin: 2em 2em

            h1
                text-align: center
                font-size: 3.5em
                margin-bottom: .3em
                color: #F44336

            .subtitle
                text-align: center

        .card-wrapper
            +flexbox(center, stretch, row, wrap)
            margin: 6em 0 0
            overflow: hidden
            border-radius: .4em
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)
            +breakpoint('<tablet')
                margin: 0 0 2em
                box-shadow: none
                border-radius: 0
            +breakpoint('<phone')
                margin-top: 0

            .section-title
                background: none
                border: none
                padding-bottom: 1em
                font-size: .9em
                font-weight: bold
                color: inherit
                letter-spacing: .04em
                +breakpoint('<phone')
                    font-size: 1.1em

            .left-section
                color: rgba(0, 0, 0, .75)
                padding: 3em 2em

                .upper-row
                    margin: 1em 0 1.8em
                    width: ($input-length * 2) - .22
                    +breakpoint('<small-phone')
                        width: ($input-length * 2) - 3

                .group
                    position: relative
                    margin: 1em 0

                textarea
                    position: relative
                    width: $input-length * 2.2
                    resize: none
                    +breakpoint('<small-phone')
                        width: $input-length * 2.05

                    &:focus ~ .bar:before
                        width: ($input-length * 2.2) - 3.75
                        +breakpoint('<small-phone')
                            width: ($input-length * 2.05) - 3.5

                input
                    width: $input-length

                    &:focus ~ .bar:before
                        width: $input-length - 1.7

                input,
                textarea
                    background: none
                    color: rgba(0, 0, 0, .7)
                    font-size: .9em
                    padding: .5em 0
                    display: block
                    border: none
                    border-radius: 0
                    border-bottom: .04em solid rgba(0, 0, 0, .6)

                    &:focus
                        outline: none

                    &:focus ~ label,
                    &:valid ~ label
                        top: -1em
                        font-size: .8em
                        color: #1E88E5

                label
                    color: rgba(0, 0, 0, 0.4)
                    font-size: .9em
                    letter-spacing: .04em
                    position: absolute
                    pointer-events: none
                    left: 0
                    top: .7em
                    transition: .3s ease all

                .bar
                    position: relative
                    display: block
                    width: 16em

                    &:before
                        content: ''
                        height: .13em
                        width: 0
                        bottom: 0
                        position: absolute
                        background: #1E88E5
                        transition: .3s ease all
                        left: 0%
                .lower-section
                    +flexbox(space-between, center, row, nowrap)
                    margin-top: 2em
                    button, .send
                        color: #ffffff
                        font-weight: bold
                        font-size: .9em
                        letter-spacing: .08em
                        padding: .7em 1em
                        border-radius: .4em
                    button
                        apperance: none
                        border: none
                        cursor: pointer
                        background: #1E88E5
                        background: linear-gradient(to bottom left, #1E88E5, #5BA8EC)
                        
                        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)
                        &:hover
                            background: linear-gradient(to bottom left, #1970BC, #5BA8EC)
                    .send
                        background: rgba(0,0,0,.7)

            .right-section
                position: relative
                padding: 3em 2em
                width: 23em
                color: #ffffff
                background: #1E88E5
                background: linear-gradient(to bottom left, #1E88E5, #5BA8EC)
                +breakpoint('<tablet')
                    width: 100%
                    height: 30em
                +breakpoint('<phone')
                    padding-top: 2em

                .titles-selection
                    margin-bottom: 1.3em

                    .active
                        pointer-events: none

                    .section-title:not(.active)
                        cursor: pointer
                        opacity: .5
                        transition: opacity .3s linear

                        &:hover
                            opacity: 1

                .first-list
                    +breakpoint('<phone')
                        margin-left: 1.3em

                li
                    +flexbox(flex-start, center, row, nowrap)
                    margin: .7em 0
                    font-size: .8em
                    letter-spacing: .05em
                    +breakpoint('<phone')
                        font-size: 1.1em
                        margin: 1.1em 0

                    .ico
                        margin-right: .8em
                        background: #fff
                        +breakpoint('<tablet')
                            font-size: 1.8em

                .social-bar
                    +flexbox(flex-start, center, row, nowrap)
                    position: absolute
                    bottom: 3em
                    +breakpoint('<tablet')
                        right: 2em

                    li
                        +flexbox(flex-start, center, row, nowrap)
                        margin: 1em .5em

                        .instagram, .facebook, .youtube, .tiktok
                            background: #fff

                .map
                    +flexbox(center, center, column, nowrap)
                    position: absolute
                    width: 100%
                    height: calc(100% - 5.5em)
                    bottom: .5em
                    left: 0

                    .map-content
                        position: relative
                        height: 100%
                        width: 95%
                        +breakpoint('<tablet')
                            width: 50%
                        +breakpoint('<phone')
                            width: 97%
        .small-text
            +flexbox(center, center, row, wrap)
            margin: 1em 0 2em
            +breakpoint('<tablet')
                margin-bottom: 5em
            +breakpoint('<phone')
                font-size: 1.8em
                margin-bottom: 2em

            .subtitle
                .credits-name
                    margin-left: .1em
                    color: #F44336
                    font-weight: bold
                    text-decoration: none

</style>
