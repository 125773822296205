<template lang="pug">
    .container
        h1.title La squadra
        .loader-container(v-if="load")
            loader
        template(v-else)
            .subtitle La nostra squadra è attualmente composta da {{volounteersLength}} volontari
            router-link(to="/formazione" tag="a").subtitle Se sei interessato a diventare un volontario clicca qui
            .chips-container
                button.chip(@click="setFilter('caposquadra')" :class='{"selected": "caposquadra" === filterCategory}')
                    .intern-chip.caposquadra
                        .chip-icon.flame-big
                        span Capo Squadra Volontario
                button.chip(@click="setFilter('capo_distaccamento')" :class='{"selected": "capo_distaccamento" === filterCategory}')
                    .intern-chip.capo_distaccamento
                        .chip-icon.flame-big
                        span Capo distaccamento
                button.chip(@click="setFilter('vice_capo_distaccamento')" :class='{"selected": "vice_capo_distaccamento" === filterCategory}')
                    .intern-chip.vice_capo_distaccamento
                        .chip-icon.flame-big
                        span Vice-capo distaccamento
                button.chip(@click="setFilter('volontario')" :class='{"selected": "volontario" === filterCategory}')
                    .intern-chip.volontario
                        .chip-icon.flame-big
                        span Volontario
            .flex-container
                .volounteer-card(v-for="volunteer in filteredVolunteers")
                    .divider
                    .tag-container
                        .tag(v-for="category in volunteer.categories")
                            .flame(:class='category.style')
                    .circular-cover-container
                        img(:src='volunteer.image')
                    .text-box
                        h1 {{volunteer.firstName}} {{volunteer.lastName}}
                        .upper-sub-container
                            span(:class='volunteer.drivingLicense.icon')
                            label {{volunteer.drivingLicense.name}}
                        .tags-container
                            span(v-for="specialization in getSpecializations(volunteer)" ) {{specialization}}
        div(v-show="filterCategory===null")
            h1.title Volontari non più operativi presso il Distaccamento di Molinella
            .flex-container
                .small-card(v-for="volunteer in inactiveVolunteers") {{volunteer.firstName}} {{volunteer.lastName}}

</template>

<script>
import axios from '../utilities/axios'

export default {
    name: 'Volontari',
    data() {
        return {
            load: false,
            filterCategory: null,
            volunteers: []
        }
    },
    mounted() {
        this.getVolunteers()
    },
    computed: {
        activeVolunteers() {
            return this.volunteers.filter(volunteer => volunteer.active)
        },
        inactiveVolunteers() {
            return this.volunteers.filter(volunteer => !volunteer.active)
        },
        filteredVolunteers() {
            if (this.filterCategory === null) return this.activeVolunteers

            return this.activeVolunteers.filter(volunteer => {
                const categories = volunteer.categories.reduce((reducer, category) => reducer.concat(category.style), [])
                return categories.includes(this.filterCategory)
            })
        },
        volounteersLength() {
            return this.activeVolunteers.length
        }
    },
    methods: {
        async getVolunteers() {
            this.load = true
            const response = await axios.get('volunteer/')
            this.load = false
            this.volunteers = response.data.sort((a, b) => a.lastName.localeCompare(b.lastName))
        },
        setFilter(filterType) {
            // Se è già selezionato lo deseleziona
            this.filterCategory = this.filterCategory === filterType ? null : filterType
        },
        getSpecializations(volunteer) {
            const specializations = []

            if (volunteer.saf) specializations.push(`SAF ${volunteer.saf}`)

            if (volunteer.nbcr) specializations.push('NBCR')

            if (volunteer.tpss) specializations.push('TPSS')

            if (volunteer.atp) specializations.push('ATP')

            if (volunteer.blsd) specializations.push('BLSD')

            return specializations
        }
    }
}
</script>

<style lang="sass" scoped>
    @import "../assets/global"
    $icon-size: 24px

    .intern-chip,
        span
            -webkit-background-clip: text !important
            -webkit-text-fill-color: transparent
            box-decoration-break: clone
            text-shadow: none

    .subtitle
        text-align: center

    .flame-big
        +icon($icon-size*1.65, 346px)

    .caposquadra
        .flame-big, span
            +caposquadra()

    .capo_distaccamento
        .flame-big, span
            +capoDistaccamento()

    .vice_capo_distaccamento
        .flame-big, span
            +viceCapoDistaccamento()

    .volontario
        .flame-big, span
            +volontario()

    .chips-container
        +flexbox(center, center, row, wrap)
        padding: 2em

        .chip
            +flexbox(center, center, row, nowrap)
            font-family: 'Nunito', sans-serif
            font-size: 16px
            background: #fff
            font-weight: bold
            letter-spacing: .04em
            margin: .2em .4em
            padding: 0
            border: .02em solid rgba(0, 0, 0, .14)
            border-radius: 2em
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)
            transition: background 0.3s ease
            +breakpoint("<phone")
                font-size: 15px
                margin: .5em .4em

            .intern-chip
                +flexbox(center, center, row, nowrap)
                max-height: 1.8em
                overflow: hidden
                padding: 1.05em .8em 1.05em .3em

                .chip-icon
                    width: 1.5em
                    height: 1.5em
                    border-radius: 50%
                    overflow: hidden
                    margin-right: .2em

            &:hover
                background: rgba(0, 0, 0, .06)

            &.selected,
            &:active
                background: rgba(0, 0, 0, .14)

    .flex-container
        +breakpoint('<small-phone')
            font-size: 13px

    .volounteer-card
        +flexbox(center, center, column, nowrap)
        height: 19em
        margin: 4em
        background-color: rgba(0, 0, 0, .10)
        border: 0.07em solid rgba(0, 0, 0, 0.01)
        border-radius: .6em
        padding-top: .8em
        overflow: hidden
        position: relative
        +breakpoint('<tablet')
            margin: 2em

        .divider
            position: absolute
            background-color: #fff
            top: -5em
            height: 65%
            width: 150%
            transform: rotate(-20deg)

        .tag-container
            position: absolute
            height: 8em
            width: 8em
            top: 1.5em

            .tag:first-child
                left: .5em
                top: -.5em

            .tag:nth-child(2)
                top: 3em
                left: -1.7em

            .tag
                +flexbox(center, center, column, nowrap)
                color: #F44336
                background-color: #fff
                position: absolute
                z-index: 3
                border-radius: 50%
                padding: .25em .22em
                box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)

                .flame
                    +icon($icon-size, 336px)

                .caposquadra
                    +caposquadra()

                .capo_distaccamento
                    +capoDistaccamento()

                .vice_capo_distaccamento
                    +viceCapoDistaccamento()

                .volontario
                    +volontario()

        .circular-cover-container
            +flexbox(center, center, column, nowrap)
            z-index: 2
            position: relative
            margin-bottom: -3.5em
            height: 10em
            width: 10em
            border-radius: 50%
            border: .4em solid #fff
            overflow: hidden

            img
                position: relative
                height: 110%

        .text-box
            +flexbox(center, center, column, nowrap)
            width: 18.6em
            min-height: 10.4em
            padding: 4em 1em 1em

            > *
                line-height: 1.6em

            h1
                font-size: 1em
                letter-spacing: .036em
                color: rgba(0, 0, 0, .86)
                font-weight: 600

            .upper-sub-container
                +flexbox(center, center, row, nowrap)
                position: relative
                width: 70%

                label
                    font-size: .8em
                    font-weight: bold
                    letter-spacing: .06em
                    opacity: .6
                    padding-top: 0.2em

                span
                    margin-right: .6em

                .prima-patente
                    +icon($icon-size, 432px)

                .seconda-patente
                    +icon($icon-size, 410px)

                .terza-patente
                    +icon($icon-size, 385px)

                .quarta-patente
                    +icon($icon-size, 361px)

            .tags-container
                +flexbox(center, center, row, nowrap)
                margin-top: .5em

                span
                    background: #fff
                    border-radius: 1.2em
                    color: rgba(0, 0, 0, .7)
                    border: .05em solid rgba(0, 0, 0, .07)
                    font-size: .73em
                    letter-spacing: .09em
                    margin: 0 .3em
                    padding: 0 .8em
                    font-weight: 600
                    +breakpoint('<phone')
                        font-size: .6em

    .small-card
        margin: 1em
        border: .05em solid #1E88E5
        padding: .8em 1em
        border-radius: .3em
        font-weight: bold
        letter-spacing: .04em
        color: rgba(0, 0, 0, .7)

</style>
