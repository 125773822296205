<template lang="pug">
    router-view
</template>

<script>
    export default {
        name: 'app'
    }
</script>

<style type="sass">

</style>
