<template lang="pug">
    .container(v-show="modalOpen")
        .flex-modal
            .dark-cover(@click="closeModal()")
            transition(name="fade")
                .modal(v-if="images.length > 0")
                    span.prev-arrow(@click="prev()")
                        .left-arrow(:class="{'disabled': firstImage}")
                    .image-panel
                        img(:src="currentImage")
                        .transparent-panel
                            .disc-container
                                .disc(v-for="(image, i) in images" :class="{'active': currentIndex === i}" @click="setIndex(i)")
                    span.next-arrow(@click="next()")
                        .right-arrow(:class="{'disabled': lastImage}")
</template>

<script>
export default {
    name: 'Modal-image',
    data() {
        return {
            currentIndex: 0
        }
    },
    created() {
        this.beforeOpen()
    },
    destroyed() {
        this.beforeClose()
    },
    computed: {
        modalOpen() {
            return this.index === undefined ? this.images.length > 0 : this.index !== null
        },
        firstImage() {
            return this.currentIndex === 0
        },
        lastImage() {
            return this.currentIndex === this.images.length - 1
        },
        currentImage() {
            return this.images[this.currentIndex].image
        }
    },
    methods: {
        closeModal() {
            this.currentIndex = 0
            this.$emit('close')
        },
        closeKey(e) {
            e.preventDefault()
            // Se viene cliccato il tasto 'esc' si chiude il modal
            if (e.key === 'Esc' || e.key === 'Escape') {
                this.closeModal()
            }
        },
        toggleContainerClass(addRemoveClass, className, footerClassName) {
            const page = document.getElementById('main_page')
            const footer = document.getElementById('footer')
            if (addRemoveClass === 'addClass') {
                page.classList.add(className)
                footer.classList.add(footerClassName)
            } else {
                page.classList.remove(className)
                footer.classList.remove(footerClassName)
            }
        },
        beforeOpen() {
            document.addEventListener('keyup', this.closeKey)
        },
        beforeClose() {
            document.removeEventListener('keyup', this.closeKey)
        },
        prev() {
            if (!this.firstImage) this.currentIndex--
        },
        next() {
            if (!this.lastImage) this.currentIndex++
        },
        setIndex(index) {
            this.currentIndex = index
        }
    },
    watch: {
        index(val) {
            if (val !== undefined && val !== null) {
                this.currentIndex = val
            }
        },
        modalOpen(newStatus, oldStatus) {
            if (!oldStatus && newStatus) {
                this.toggleContainerClass('addClass', 'scroll-locked', 'footer-scroll-locked')
            } else if (oldStatus && !newStatus) {
                this.toggleContainerClass('removeClass', 'scroll-locked', 'footer-scroll-locked')
            }
        }
    },
    props: {
        images: {
            required: true
        },
        index: {
            required: false
        }
    }
}
</script>


<style lang="sass" scoped>
    @import "../assets/global"
    .fade-enter-active, .fade-leave-active
        transition: opacity .3s ease

    .fade-enter, .fade-leave-to
        opacity: 0

    .left-arrow
        +icon(24px,456px)
        transform: rotate(180deg)
    .right-arrow
        +icon(24px,456px)

    .prev-arrow, .next-arrow
        .disabled
            opacity: 0
            cursor: default !important


    .flex-modal
        +flexbox(center, center, column, nowrap)
        position: absolute
        top: 0
        bottom: 0
        right: 0
        left: 0
        
        .dark-cover
            z-index: 6
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            background: rgba(0, 0, 0, .55)

        .modal
            z-index: 7
            +flexbox(center, center, row, nowrap)
            display: inline-flex
            position: absolute
            height: 90%
            max-width: 80%
            margin: 0 auto
            +breakpoint('<mid-desktop')
                height: 100%
            +breakpoint('<phone')
                height: 0
                max-width: unset
                width: 95%


            span
                .left-arrow, .right-arrow
                    cursor: pointer
                    background: #fff

            .image-panel
                +flexbox(center, center, row, nowrap)
                height: 80%
                border-radius: .6em
                margin: 0 3em
                overflow: hidden
                position: relative
                +breakpoint('<tablet')
                    height: unset
                +breakpoint('<phone')
                    margin: 0 .4em
                img
                    height: 100%
                    width: initial
                    +breakpoint('<tablet')
                        height: auto
                        width: 100%
                .transparent-panel
                    +flexbox(center, flex-end, row, nowrap)
                    position: absolute
                    top: 0
                    bottom: 0
                    left: 0
                    right: 0
                    .disc-container
                        +flexbox(center, center, row, nowrap)
                        position: absolute
                        padding-bottom: 1em
                        +breakpoint('<small-phone')
                            opacity: 0
                            pointer-events: none
                        .disc
                            cursor: pointer
                            height: .4em
                            width: .4em
                            margin: 0 .2em
                            border-radius: 50%
                            background: rgba(255, 255, 255, .3)
                            +breakpoint('<phone')
                                height: .3em
                                width: .3em
                                margin: 0 .15em
                        .active
                            background: rgba(255, 255, 255, .85)
</style>
