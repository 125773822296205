import Vue from 'vue'
import App from './App.vue'
import router from '@/router.js'
import './assets/main.sass'
import '@mdi/font/scss/materialdesignicons.scss'
import Loader from './components/Loader'
import * as moment from 'moment'

Vue.config.productionTip = false
Vue.component('loader', Loader)

moment.locale('it')

new Vue({
    render: h => h(App),
    router
}).$mount('#app')
