<template lang="pug">
    div(v-if="!error")
        .container
            .loader-container(v-if="load")
                loader
            div(v-else)
                h1.title {{album.name}}
                .mini-grid-container
                    .back-arrow(@click="$router.push({ name: 'Foto'})")
                        .arrow
                    .photo-container(v-for="(image, index) in album.albumImage")
                        img(:src="image.image" @click="changeIndex(index)")
            modal-foto(v-if="!loading" :images="album.albumImage" :index="currentIndex" @close="closeModal()")
    album-non-trovato(v-else)

</template>

<script>
import axios from '../utilities/axios'
import ModalFoto from './ModalFoto.vue'
import AlbumNonTrovato from './AlbumNonTrovato'

export default {
    name: 'Album',
    data() {
        return {
            load: true,
            album: null,
            currentIndex: null,
            error: false
        }
    },
    mounted() {
        this.getEvent()
    },
    methods: {
        async getEvent() {
            try {
                this.load = true
                const response = await axios.get(`album/${this.$route.params.id}/`)
                this.load = false
                this.album = response.data
            } catch (e) {
                this.error = true
            }
        },
        closeModal() {
            this.currentIndex = null
        },
        changeIndex(index) {
            this.currentIndex = index
        }
    },
    components: {
        ModalFoto,
        AlbumNonTrovato
    }
}
</script>


<style lang="sass" scoped>
    @import "../assets/global"

    .title
        text-align: center

    .mini-grid-container
        display: grid
        grid-template-columns: 9em 9em 9em
        grid-gap: 1.5em
        position: relative
        width: 30em
        padding: 3em 0
        font-size: 28px
        +breakpoint('<tablet')
            font-size: 20px
        +breakpoint('<phone')
            font-size: 12px
            grid-gap: 1.7em
        +breakpoint('<small-phone')
            font-size: 11px
            width: 100%
            grid-gap: 1.1em
            

        .back-arrow
            cursor: pointer
            position: absolute
            left: 0
            top: -0.8em

        .photo-container
            cursor: pointer
            +flexbox(center, center, row, nowrap)
            height: 9em
            width: 9em
            border-radius: .1em
            overflow: hidden
            filter: brightness(100%)
            transition: filter .2s ease

            &:hover
                filter: brightness(80%)

            img
                position: relative
                height: 150%

</style>
