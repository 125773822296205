<template lang="pug">
    footer#footer(v-if="visible")
        div
            ul
                li.interactive-element.multiline(@click="openMap()")
                    button
                        .place
                    span {{address}}
                a.interactive-element(href="tel:3485253518")
                    button
                        .phone
                    span 348 5253518
                a.interactive-element(href="mailto:vigilfuocomolinella@gmail.com")
                    button
                        .mail
                    span vigilfuocomolinella
            .link-section
                ul
                    li
                        a(href="https://www.instagram.com/vfvmolinella/" target="_blank")
                            .instagram
                    li
                        a(href="https://www.facebook.com/vvfmolinella/" target="_blank")
                            .facebook
                    li
                        a(href="https://www.tiktok.com/@vfvmolinella" target="_blank")
                            .tiktok
                div.subtitle Made by
                    span.credits-name Massbit

</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            visible: true
        }
    },
    mounted() {
        this.visible = this.$route.path !== '/contatti' && this.$route.path !== '/antonino'
    },
    computed: {
        address() {
            if (this.$route.path === '/onlus' || this.$route.path === '/onlus/donazioni_pubbliche') return 'Via Giuseppe Mazzini 381, Molinella BO'

            return 'Via Aldo Moro 26, Molinella BO'
        }
    },
    methods: {
        openMap() {
            window.open('https://goo.gl/maps/bbMYUGgGjHn', '_blank')
        }
    },
    watch: {
        $route(to) {
            this.visible = to.path !== '/contatti' && to.path !== '/antonino'
        }
    }
}
</script>

<style lang="sass" scoped>
@import "./../assets/global"
$icon-size: 24px

.place
    +icon($icon-size, 239px)

.phone
    +icon($icon-size, 215px)

.mail
    +icon($icon-size, 191px)

.instagram
    +icon($icon-size*.8, 268px)

.facebook
    +icon($icon-size*.8, 289px)

.youtube
    +icon($icon-size*.8, 309px)

.tiktok
    +icon($icon-size*.8, 581px)

footer
    position: absolute
    bottom: 0
    right: 0
    left: 0
    display: flex
    background-color: transparent
    +flexbox(center, center, column, nowrap)
    color: rgba(0, 0, 0, .74)
    border-top: .04em solid rgba(0, 0, 0, .07)

    div
        +flexbox(center, center, column, nowrap)

        > *
            cursor: pointer

    .link-section
        display: inline-flex
        +flexbox(center, center, column, nowrap)
        vertical-align: middle

    ul
        display: inline-flex

        li
            display: inline-block
            margin: 0 .78em
            +breakpoint('<tablet')
                margin: 0 1.75em .5em

            a
                text-decoration: none

            +breakpoint('<small-phone')
                margin: 0 .85em

        .interactive-element
            text-decoration: none
            +flexbox(center, center, row, wrap)
            margin: .8em
            height: 3.5em
            width: auto
            border-radius: 2em
            +breakpoint('<tablet')
                margin: 1.2em .7em
            +breakpoint('<small-phone')
                margin: .15em

            > *
                position: relative

            button
                cursor: pointer
                background: none
                border: none
                background-color: rgba(0, 0, 0, 0)
                outline: none
                width: 4em
                border-radius: .4em 0 0 .4em
                height: 100%
                display: inline-flex
                +flexbox(center, center, row, nowrap)
                color: inherit
                transition: background-color 0.3s ease
                +breakpoint('<tablet')
                    border-radius: .4em
                +breakpoint('<small-phone')
                    width: 3.2em
                    height: 3em

            span
                border: .1em solid rgba(0, 0, 0, 0)
                display: inline-flex
                flex: none
                border-left: 0
                width: 10em
                height: 100%
                text-align: center
                +flexbox(center, center, row, nowrap)
                word-break: break-word
                border-radius: 0 .4em .4em 0
                font-size: .85em
                letter-spacing: .02em
                font-weight: bold
                color: rgba(0, 0, 0, .5)
                transition: border-color 0.3s ease
                +breakpoint('<tablet')
                    display: none

            &:hover
                button
                    background-color: rgba(229, 57, 53, .8)

                    .place, .phone, .mail
                        background: #fff
                        z-index: 2

                span
                    border-color: rgba(229, 57, 53, .7)

    .subtitle
        +flexbox(center, center, row, nowrap)
        margin: 0 0 1em
        font-size: 13px

        .credits-name
            margin-left: .4em
            color: #F44336
            font-weight: bold
            text-decoration: none
</style>
