<template lang="pug">
    .container(v-show="project !== null")
        .flex-modal
            .dark-cover(@click="closeModal()")
            transition(name="fade")
                .project-modal(v-if="project !== null")
                    span.prev-arrow(@click="prev()")
                        .left-arrow(:class="{'disabled': firstImage}")
                    .main-panel
                        .image-panel
                            img(:src="currentImage")
                            .transparent-panel
                                .disc-container
                                    .disc(v-for="(image, i) in images" :class="{'active': currentIndex === i}" @click="setIndex(i)")
                        .text-panel
                            .element-title {{project.name}}
                            .subtitle {{project.description}}
                            .subtitle.money(v-if="project.price !== null") {{getDonationPriceString(project)}}
                            .collaborators-container(v-if="project.completed")
                                .subtitle Un grazie a:
                                .subtitle(v-if="project.private_donation") Libere donazioni privati
                                .flex-container
                                    img.logo(v-for="company in project.companies" :src="company.logo")
                    span.next-arrow(@click="next()")
                        .right-arrow(:class="{'disabled': lastImage}")
</template>

<script>
    export default {
        name: 'ModalProgetto',
        data() {
            return {
                currentIndex: 0
            }
        },
        created() {
            this.beforeOpen()
        },
        destroyed() {
            this.beforeClose()
        },
        computed: {
            images() {
                return this.project.images
            },
            firstImage() {
                return this.currentIndex === 0
            },
            lastImage() {
                return this.currentIndex === this.images.length - 1
            },
            currentImage() {
                return this.images[this.currentIndex].image
            }
        },
        methods: {
            closeModal() {
                this.$emit('close')

                this.currentIndex = 0
            },
            closeKey(e) {
                e.preventDefault()
                // Se viene cliccato il tasto 'esc' si chiude il modal
                if (e.key === 'Esc' || e.key === 'Escape') {
                    this.closeModal()
                }
            },
            toggleContainerClass(addRemoveClass, className, footerClassName) {
                const page = document.getElementById('main_page')
                const footer = document.getElementById('footer')
                if (addRemoveClass === 'addClass') {
                    page.classList.add(className)
                    footer.classList.add(footerClassName)
                } else {
                    page.classList.remove(className)
                    footer.classList.remove(footerClassName)
                }
            },
            beforeOpen() {
                document.addEventListener('keyup', this.closeKey)
            },
            beforeClose() {
                document.removeEventListener('keyup', this.closeKey)
            },
            prev() {
                if (!this.firstImage) this.currentIndex--
            },
            next() {
                if (!this.lastImage) this.currentIndex++
            },
            setIndex(index) {
                this.currentIndex = index
            },
            getDonationPriceString(project) {
                if (project.completed) return `Sono stati donati €${Number(project.price.toFixed(2)).toLocaleString('it')}`

                return `Importo richiesto: €${Number(project.price.toFixed(2)).toLocaleString('it')}`
            }
        },
        watch: {
            project(newProject, oldProject) {
                if (newProject !== null && oldProject === null) {
                    this.toggleContainerClass('addClass', 'scroll-locked', 'footer-scroll-locked')
                } else if (newProject === null && oldProject !== null) {
                    this.toggleContainerClass('removeClass', 'scroll-locked', 'footer-scroll-locked')
                }
            }
            /*
                modalOpen(project) {
                    if (project !== null) this.toggleContainerClass('addClass', 'scroll-locked', 'footer-scroll-locked')
                    else this.toggleContainerClass('removeClass', 'scroll-locked', 'footer-scroll-locked')
                }
                 */
        },
        props: {
            project: {
                required: true
            }
        }
    }
</script>


<style lang="sass" scoped>
    @import "../assets/global"

    .fade-enter-active, .fade-leave-active
        transition: opacity .3s ease

    .fade-enter, .fade-leave-to
        opacity: 0

    .left-arrow
        +icon(24px, 456px)
        transform: rotate(180deg)

    .right-arrow
        +icon(24px, 456px)

    .prev-arrow, .next-arrow
        .disabled
            opacity: 0
            cursor: default !important


    .flex-modal
        +flexbox(center, center, column, nowrap)
        position: absolute
        top: 0
        bottom: 0
        right: 0
        left: 0
        font-size: 16px
        +breakpoint('<tablet')
            font-size: 13px

        .dark-cover
            z-index: 6
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            background: rgba(0, 0, 0, .55)

        .project-modal
            z-index: 7
            +flexbox(center, center, row, nowrap)
            position: absolute
            max-width: 80%
            +breakpoint('<tablet')
                max-width: 100%

            span
                .left-arrow, .right-arrow
                    cursor: pointer
                    background: #fff

            .main-panel
                +flexbox(center, center, row, nowrap)
                width: 75%
                margin: 0 3em
                +breakpoint('<tablet')
                    align-items: stretch
                +breakpoint('<phone')
                    margin: 0 1em
                    flex-wrap: wrap
                +breakpoint('<small-phone')
                    margin: 0 .5em

                .image-panel
                    +flexbox(center, center, row, nowrap)
                    width: 80%
                    height: 42.5em
                    border-radius: .6em 0 0 .6em
                    overflow: hidden
                    position: relative
                    background: #000
                    +breakpoint('<tablet')
                        height: auto
                    +breakpoint('<phone')
                        border-radius: .6em .6em 0 0
                        width: 100%
                        height: 11em

                    img
                        position: relative
                        width: 100%
                        +breakpoint('<phone')
                            width: auto
                            height: 100%

                    .transparent-panel
                        +flexbox(center, flex-end, row, nowrap)
                        position: absolute
                        top: 0
                        bottom: 0
                        left: 0
                        right: 0

                        .disc-container
                            +flexbox(center, center, row, nowrap)
                            position: absolute
                            padding-bottom: 1em

                            .disc
                                cursor: pointer
                                height: .4em
                                width: .4em
                                margin: 0 .2em
                                border-radius: 50%
                                background: rgba(255, 255, 255, .3)

                            .active
                                background: rgba(255, 255, 255, .85)

                .text-panel
                    position: relative
                    min-height: 42.5em
                    max-height: 42.5em
                    max-width: 28em
                    overflow-y: auto
                    background: #fff
                    padding: 2em 3em
                    border-radius: 0 .6em .6em 0
                    +breakpoint('<tablet')
                        min-height: auto
                        max-height: unset
                    +breakpoint('<phone')
                        max-width: unset
                        width: 100%
                        padding: 1.3em 2em
                        border-radius: 0 0 .6em .6em
                    +breakpoint('<small-phone')
                        padding-bottom: 0

                    .money
                        color: rgba(0, 0, 0, .8)

                    .collaborators-container
                        padding-top: 1em
                        margin-top: 2em
                        border-top: .04em solid rgba(0, 0, 0, .07)
                        +breakpoint('<small-phone')
                            padding-top: 0
                            margin-top: .8em

                        .flex-container
                            +breakpoint('<small-phone')
                                padding-bottom: 0

                            .logo
                                width: 7em
                                margin: 2em
                                +breakpoint('<phone')
                                    width: 3em
                                    margin: .8em .5em

</style>
