<template lang="pug">
    .container
        h1.title Evento non trovato
        .img-container
            .calendar
        .subtitle Ci dispiace ma l'evento che stai cercando non esiste ancora.
        a(@click="$router.push({name: 'Eventi'})") Torna alla pagina degli eventi.
</template>

<script>
export default {
    name: 'EventoNonTrovato'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
    @import "../assets/global"

    .calendar
        position: relative
        height: 100%
        width: 100%
        +sprite(center, 351px, 320px)
        +breakpoint("<phone")
            +sprite(center, 685px, 160px)

    .img-container
        height: 20em
        width: 20em
        margin: 3em 0
        +breakpoint('<phone')
            height: 10em
            width: 10em
            margin: .4em 0 3em
    .subtitle
        padding: 0 .5em
        text-align: center
    a
        padding: 1em 0 2em
        font-size: .85em
        font-weight: bold
        letter-spacing: .04em
        color: #1E88E5
        cursor: pointer

</style>
