<template lang="pug">
    lottie(:options="defaultOptions" :height="120" :width="120")
</template>

<script>
import Lottie from 'vue-lottie'
import animationData from '../assets/loader.json'

export default {
    name: 'Loader',
    components: {
        Lottie
    },
    data() {
        return {
            defaultOptions: {
                animationData: animationData,
                autoplay: true,
                loop: true
            }
        }
    }
}
</script>

<style scoped>
</style>