<template lang="pug">
    div
        .vehicle-card
            .cover-container(@click="modalOpen=true")
                img(:src='getCover')
            .text-container
                .upper-text
                    .element-title.shorted {{item.name}}
                .subtitle.veichle-description(:class="{'unrolled': descriptionOpen}") {{item.description}}
                .bottom-wrapper
                    .subtitle.date-text.subtitle-no-padding Acquistato nel {{item.purchaseDate}}
                    .icon(:class="{'rotate': descriptionOpen}" v-on:click="toggleDescription")
                        .arrow-down

        modal-foto(:images="selectedImages" @close="closeModal()")
</template>

<script>
import ModalFoto from './ModalFoto.vue'

export default {
    name: 'Card-automezzo',
    data() {
        return {
            descriptionOpen: false,
            modalOpen: false
        }
    },
    computed: {
        getCover() {
            console.log(this.item)
            return this.item.images.find(image => image.cover).image
        },
        selectedImages() {
            return this.modalOpen ? this.item.images : []
        }
    },
    methods: {
        toggleDescription() {
            this.descriptionOpen = !this.descriptionOpen
        },
        closeModal() {
            this.modalOpen = false
        }
    },
    props: {
        item: {
            required: true
        }
    },
    components: {
        ModalFoto
    }
}
</script>

<style lang="sass" scoped>
    @import "../assets/global"

    .icon
        cursor: pointer
        transition: transform .2s linear

        &.rotate
            transform: rotate(180deg)

        .arrow-down
            +icon(24px, 456px)
            background: rgba(30, 136, 229, .7)
            transform: rotate(90deg)

    .subtitle-no-padding
        padding-top: 0


    .vehicle-card
        +flexbox(center, center, column, nowrap)
        width: 20em
        margin: 4em 3em
        overflow: hidden
        +breakpoint('<tablet')
            margin: 4em 1.5em 1em
        +breakpoint('<small-phone')
            margin: 3em 0 1em
            font-size: 14px

        .cover-container
            img
                position: relative
                width: 100%

        .text-container
            padding: 0 .5em .5em
            position: relative
            width: 100%

            .upper-text
                +flexbox(flex-start, center, row, nowrap)

            .veichle-description
                white-space: pre-wrap
                height: 2em
                overflow: hidden
                text-overflow: ellipsis

            .unrolled
                height: auto

            .bottom-wrapper
                +flexbox(space-between, center, row, nowrap)
                padding-top: .4em

                .date-text
                    text-transform: uppercase
                    color: rgba(0, 0, 0, 0.6)
                    font-size: .7em

                .button-show
                    cursor: pointer
                    color: #1E88E5

</style>
