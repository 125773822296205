<template lang="pug">
    .container
        h1.title Diventare volontario
        .text-container
            .important Arruolarsi nel corpo nazionale dei Vigili del Fuoco come volontario è molto facile.
            .subtitle
                span I prerequisiti sono:
                ul
                    li Cittadinanza italiana
                    li Età compresa tra i 18 e 45 anni
                    li Almeno del diploma di istruzione secondaria di primo grado (licenza media inferiore)
                    li Di idoneità psicofisica e attitudinale da accertarsi a cura dei competenti Comandi provinciali che possono avvalersi anche delle strutture del servizio sanitario nazionale
                    li Della residenza in un comune della provincia sede del Comando per il quale si richiede l’iscrizione; per i candidati che chiedono di essere impiegati in una sede volontaria è possibile l’arruolamento anche se risiedono in un provincia limitrofa a quella della sede volontaria richiesta
                    li Dei diritti politici e non essere stati destituiti , dispensati o licenziati dall’impiego presso un pubblica amministrazione
                    li Dei requisiti di qualità; morali e di condotta, di cui all’art. 35, comma 6 del decreto legislativo 165/2001
                    li Della posizione regolare nei confronti degli obblighi di leva, il che significa che possono inoltrare domanda di arruola mento anche coloro (incluse le donne) che non hanno svolto il servizio militare a meno che non abbiano disertato o siano renitenti al servizio di leva
                    li Della compatibilità con il servizio di volontariato di vigile del fuoco.
            .important Non possono essere accolte le domande di coloro che: svolgono già la professione di vigile del fuoco permanente,di militare nelle forze armate o di polizia dello Stato oppure che siano amministratori o titolari di imprese che producono, installano, commercializzano impianti, dispositivi ed attrezzature antincendio e i titolari di aziende nel settore delle attività di formazione, vigilanza o consulenza in materia di antincendio.
            .subtitle La presentazione della domanda di arruolamento è seguita da una visita medica preliminare da effettuarsi presso il Comando provinciale ed una successiva, più accurata, presso una struttura convenzionata (Es. Ferrovie dello Stato, ASL, ecc.).
            .subtitle Espletato l’iter sanitario e con idonei requisiti di accesso, tutta la documentazione viene inviata al Dipartimento dei vigili del fuoco presso il Ministero dell’Interno il quale entro circa due mesi dovrebbe rilasciare il decreto ministeriale di nomina. Con il decreto di nomina, l’aspirante vigile volontario è così pronto per effettuare il corso di formazione della durata di circa 120 ore presso il Comando provinciale di appartenenza con lezioni generalmente effettuate nei giorni festivi e prefestivi per venire incontro alle esigenze lavorative e di studio degli interessati.
            .subtitle Terminato il corso e superato l’esame finale, finalmente si diventa vigili volontari con tutti gli obblighi e i doveri spettanti ad un vigile del fuoco.
            .important Per maggiori informazioni contattaci o vieni a trovarci presso il nostro distaccamento.
        a(href="https://vfvmolinella.s3.eu-central-1.amazonaws.com/static/documents/modello_iscrizione.pdf" target="_blank").download-button Scarica il modulo di domanda
            .icon
                .arrow
                .base

</template>

<script>
export default {
    name: 'Formazione'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
    @import "../assets/global"
    .text-container
        +flexbox(center, flex-start, column, nowrap)
        max-width: 70%
        padding-top: 3em
        font-size: 18px
        +breakpoint('<tablet')
            max-width: 80%
        +breakpoint('<phone')
            max-width: 90%
        +breakpoint('<small-phone')
            padding-top: 0
        ul
            list-style-type: disc
            padding-left: 1em
        .subtitle
            line-height: 1.3em
        .important
            line-height: 1.2em
            padding-top: .9em
            font-size: .9em
            color: rgba(0, 0, 0, 0.6)
            font-weight: bold
    .download-button
        margin-bottom: 4em

</style>
