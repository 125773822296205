<template lang="pug">
    .container
        h1.title Album non trovato
        .img-container
            .pictures
        .subtitle Ci dispiace ma l'album che stai cercando non esiste ancora.
        a(@click="$router.push({name: 'Foto'})") Torna alla pagina degli album.
</template>

<script>
export default {
    name: 'AlbumNonTrovato'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
    @import "../assets/global"

    .pictures
        position: relative
        height: 100%
        width: 100%
        +sprite(center, 710px, 320px)
        +breakpoint("<phone")
            +sprite(center, 849px, 160px)

    .img-container
        height: 16em
        width: 20em
        margin: 3em 0
        +breakpoint('<phone')
            height: 10em
            width: 10em
            margin: .4em 0 3em
    .subtitle
        padding: 0 .5em
        text-align: center
    a
        padding-top: 1em
        font-size: .85em
        font-weight: bold
        letter-spacing: .04em
        color: #1E88E5
        cursor: pointer
</style>
