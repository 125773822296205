<template lang="pug">
    .container
        .loader-container(v-if="load")
            loader
        template(v-else)
            h1.title Resoconto donazioni
            .description-container
                .subtitle Ai sensi della Legge annuale per il mercato e la concorrenza N. 124 del 4 agosto 2017 (articolo 1, dal comma 125 al comma 127) viene stabilito che entro il 28 febbraio di ogni anno a decorrere dall’anno 2018 le associazioni‚ le ODV e le fondazioni che intrattengono rapporti economici con le pubbliche amministrazioni (o enti assimilati, nonché con società controllate di diritto o di fatto direttamente o indirettamente da pubbliche amministrazioni) devono pubblicare (nei propri siti o portali digitali) le informazioni relative a sovvenzioni‚ contributi‚ incarichi retribuiti e comunque vantaggi economici di qualunque genere di importo pari o superiore a 10.000 euro‚ ricevuti dalle medesime pubbliche amministrazioni nel corso dell’anno precedente. Si specifica che l’inosservanza di tale obbligo comporta la restituzione delle somme ai soggetti eroganti entro tre mesi dalla data di cui al periodo precedente. L’associazione Vigili del Fuoco Volontari Molinella Friends Onlus, al fine di una massima trasparenza, ha deciso di rendere noto anche gli importi erogati per un importo inferiore ai € 10.000. Di seguito pubblichiamo una tabella dettagliata relativa ai contributi ricevuti.
            table
                tr
                    th Nome ente
                    th Atto
                    th Delibera/Determina
                    th Motivo
                    th Somma stanziata
                tr(v-for="donation in donations")
                    td {{donation.company}}
                    td {{donation.act}}
                    td {{donation.resolution}}
                    td {{donation.reason}}
                    td € {{Number(donation.price.toFixed(2)).toLocaleString('it')}}
            .subtitle.bottom Totale {{Number(total.toFixed(2)).toLocaleString('it')}}€
</template>

<script>
import axios from '../utilities/axios'
import * as moment from 'moment'

export default {
    name: 'Tabella',
    data() {
        return {
            load: true,
            donations: []
        }
    },
    mounted() {
        this.getDonations()
    },
    computed: {
        total() {
            return this.donations.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.price
            }, 0)
        }
    },
    methods: {
        async getDonations() {
            this.load = true
            const response = await axios.get('economic_contributions/')
            this.load = false
            const donations = response.data
            // Convert data
            this.donations = donations.map(donation => {
                return {
                    ...donation,
                    date: moment(donation.date, 'YYYYMMDD HH:mm').format('DD/MM/YYYY')
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import "../assets/global"

.description-container
    position: relative
    text-align: center
    width: 50%
    +breakpoint("<tablet")
        width: 90%
table
    font-size: 16px
    margin: 3em 0
    border-collapse: collapse
    overflow: hidden
    border-radius: .4em
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)
    +breakpoint("<phone")
        font-size: 9px
    +breakpoint("<small-phone")
        font-size: 7px

    td,th
        border: .05em solid rgba(0,0,0,.14)
        padding: .8em
        font-size: 1em
        font-weight: bold
        letter-spacing: .04em
        color: rgba(0, 0, 0, 0.7)
        text-align: left

        a
            color: #1E88E5
.bottom
    marign-bottom: 4em
</style>
