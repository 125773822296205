<template lang="pug">
    .container
        .loader-container(v-if="load")
            loader
        template(v-else)
            h1.title 5x1000
            .intro-panel
                .subtitle.intro-text Destinare il 5 x 1000 è semplice e non comporta nessuna spesa per te, essendo una quota d'imposta  a cui lo Stato rinuncia. Nel caso in cui si decida di non destinare a nessuno il 5 per mille quella parte d'imposta verrà comunque destinata allo Stato. Se scegli di destinarlo alla nostra ODV, i proventi saranno destinati completamente all'acquisto di nuove attrezzature o mezzi destinati al soccorso tecnico urgente in uso esclusivo al Distaccamento di Molinella. Destinare il 5 x 1000 è davvero semplice e per noi può fare una grande differenza!
                a.onlus-link(href="../onlus") Torna alla ODV
                .subtitle.dark Ecco nel dettaglio come procedere:
                ol.donate-list
                    li.subtitle Compila il modello 730, il CUD oppure il Modello Unico
                    li.subtitle Nella scheda per la scelta della destinazione dell'8 per mille, del 5 per mille e del 2 per mille dell'IRPEF firma alla voce "sostegno del volontariato e delle altre organizzazioni....del D.lgs n. 460 del 1997"
                    li.subtitle Inserisci nell'apposito riquadro il nostro codice fiscale 91374170370
                .subtitle Se ti appoggi ad un commercialista o ad un CAF per la compilazione del modello 730 richiedi direttamente di destinare il 5 per mille alla nostra associazione
            h1.title Grazie!
            .donations-panel
                .subtitle Elenco contributi 5 x 1000 ricevuti all'associazione vigili del Fuoco volontari Molinella Friends ODV
                .subtitle I presenti dati vengono estratti dall'elenco completo enti destinatari del contributo 5 x 1000 dal sito dell'
                    a(href="https://www.agenziaentrate.gov.it/wps/content/Nsilib/Nsi/Archivio/Archivio+5permille/") Agenzia delle Entrate
                .donations-card-container
                    .donation-card
                        .subtitle.dark Campagna 5 per mille anno 2016
                        .subtitle Numero scelte : 396
                        .subtitle Importo erogato : € 7.454,45
                    .donation-card
                        .subtitle.dark Campagna 5 per mille anno 2017
                        .subtitle Numero scelte : 630
                        .subtitle Importo erogato : € 13.322,59
                    .donation-card
                        .subtitle.dark Campagna 5 per mille anno 2018
                        .subtitle Numero scelte : 775
                        .subtitle Importo erogato : € 16.033,68
                    .donation-card
                        .subtitle.dark Campagna 5 per mille anno 2019
                        .subtitle Numero scelte : 879
                        .subtitle Importo erogato : € 18.451,17
                .subtitle.dark Cosa vorremmo acquistare grazie al tuo 5 per mille?
                .subtitle Ci piacerebbe poter acquistare altri moduli scarrabili in modo da poter utilizzare il nostro nuovo mezzo in svariate tipologie di intervento. Inoltre abbiamo in progetto di acquistare anche altre attrezzature che potrai visionare 
                    a(href="../onlus") qui
                .subtitle.dark Cosa abbiamo acquistato grazie al tuo 5 per mille?
                .subtitle Guarda sotto i progetti realizzati
            h1.title Progetti realizzati
            .flex-container
                .project-card(v-for="project in projects")
                    .cover-container-no-animate
                        img(:src="getCover(project)")
                    .text-container
                        .element-title.shorted {{project.name}}
                        .subtitle Importo: 
                            .dark € {{Number(project.price.toFixed(2)).toLocaleString('it')}}
                        .subtitle.small-description {{project.description}}

</template>

<script>
import axios from '../utilities/axios'

export default {
    name: 'Donato',
    data() {
        return {
            load: true,
            projects: []
        }
    },
    mounted() {
        this.getDonations()
    },
    methods: {
        async getDonations() {
            this.load = true
            const response = await axios.get('project/?is_workers_donation=true')
            this.load = false
            this.projects = response.data
        },
        getCover(project) {
            return project.images.find(image => image.cover).image
        }
    }
}
</script>

<style lang="sass" scoped>
    @import "../assets/global"

    a
        text-decoration: none
        color: #1E88E5

    .onlus-link
        cursor: pointer
        margin: 1em
        text-decoration: none
        font-size: .9em
        letter-spacing: .04em
        font-weight: 700
        color: #fff
        background: rgba(30,136,229,.7)
        padding: .8em 1em
        border-radius: .3em

    .dark
        color: #000
        display: inline

    .small
        +breakpoint('<phone')
            font-size: .6em

    .flex-container
        +flexbox(center, center, column, wrap)

    .project-card:nth-child(odd)
        flex-direction: row
        +breakpoint('<tablet')
            flex-direction: column

        .text-container
            align-items: flex-start
            text-align: left
            +breakpoint('<tablet')
                align-items: center
                text-align: center

    .project-card:nth-child(even)
        flex-direction: row-reverse
        +breakpoint('<tablet')
            flex-direction: column

        .text-container
            align-items: flex-end
            text-align: right
            +breakpoint('<tablet')
                align-items: center
                text-align: center

    .intro-panel,.donations-panel
        position: relative
        width: 60%
        max-width: 1000px
        +breakpoint('<tablet')
            width: 90%
    .intro-panel
        +flexbox(flex-start, center, column, nowrap)
        text-align: left
        .intro-text
            font-size: 13.4px
            line-height: 1.4em
            margin-bottom: 2em
            text-align: justify
        .donate-list
            position: relative
            width: 90%
            list-style-type: decimal
    .donations-panel
        +flexbox(center, center, column, nowrap)
        text-align: center
        .donations-card-container
            +flexbox(center, center, row, wrap)
            .donation-card
                +flexbox(center, center, column, nowrap)
                margin: 1em 0
                padding: 0 1em 1em
                border-left: .05em solid rgba(0,0,0,.14)
                border-right: .05em solid rgba(0,0,0,.14)

    .project-card
        +flexbox(center, center, initial, nowrap)
        width: auto
        max-width: 60em
        margin: 3em 0
        height: 20em
        overflow: hidden
        +breakpoint('<tablet')
            height: auto
            width: auto
        +breakpoint('<phone')
            margin: 1.5em 0
            font-size: .8em

        .cover-container-no-animate
            +flexbox(center, center, column, nowrap)
            border-radius: .4em
            overflow: hidden
            position: relative
            height: 100%
            max-width: 100%
            +breakpoint('<tablet')
                height: 16em
                width: 20em
            img
                position: relative
                height: 125%

        .text-container
            +flexbox(flex-start, inherit, column, nowrap)
            position: relative
            width: 100%
            padding: 1em 2em
            +breakpoint('<phone')
                padding: 1em
            .shorted
                +breakpoint('<tablet')
                    font-size: 1em
                    white-space: initial
            .small-description
                +breakpoint('<tablet')
                    font-size: .7em

</style>
