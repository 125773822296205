/**
 * Cambia le key formattate in snake_case o in kebab-case in camelCase
 * @param obj
 */
export function toCamelCase(obj) {
    let rtn = obj
    if (typeof obj === 'object' && obj !== null) {
        if (obj instanceof Array) {
            rtn = obj.map(toCamelCase)
        } else {
            rtn = {}
            for (const key in obj) {
                if (obj[key] !== undefined) {
                    const newKey = key.replace(/(_\w)|(-\w)/g, k => k[1].toUpperCase())
                    rtn[newKey] = toCamelCase(obj[key])
                }
            }
        }
    }
    return rtn
}

/**
 * Raggruppa gli eventi per anni
 * @param events
 * @returns {Array}
 */
export function groupEventsByYear(events) {
    const years = []

    events.forEach(event => {
        // Cerco se esiste già l'anno
        const yearIndex = years.findIndex(year => year.id === parseInt(event.date.format('YYYY'), 10))

        // Se non esiste crea l'anno
        if (yearIndex === -1) {
            years.push({
                id: parseInt(event.date.format('YYYY'), 10),
                events: [event]
            })
        } else {
            // Se no lo aggiunge semplicemente
            years[yearIndex].events.push(event)
        }
    })

    return years
}