<template lang="pug">
    .container(id="main_container")
        h1.title Le nostre attività
        .flex-container
            .activity-card(v-for='activity in activities')
                img(:src='activity.img')
                .element-title {{activity.title}}
                .subtitle {{activity.description}}
</template>

<script>
export default {
    name: 'CosaFacciamo',
    data() {
        return {
            activities: [
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/insetti.png',
                    title: 'Insetti',
                    description: 'Bonifica insetti nocivi e pericolosi (es. calabroni, vespe, ecc.).'
                },
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/aiuto.png',
                    title: 'Servizi tecnici',
                    description: 'Apertura porte, finestre e ascensori.'
                },
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/infortunio.png',
                    title: 'Infortuni',
                    description: 'Sul lavoro e in casa.'
                },
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/spazzaneve.png',
                    title: 'Eventi straordinari',
                    description: 'Danni o disagi dovuti ad acqua, vento, neve.'
                },
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/elicottero.png',
                    title: 'Soccorsi specifici',
                    description: 'Soccorsi in zone impervie (es. fiumi, pozzi, boschi) e ricerche di persone o animali.'
                },
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/inquinamento.png',
                    title: 'Ambiente',
                    description: 'Incidenti ambientali (es. inquinamento di un fiume da liquidi derivati dal petrolio).'
                },
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/frana.png',
                    title: 'Dissesti statici',
                    description: 'Crolli, frane, smottamenti e lesioni a strutture.'
                },
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/radioactive.png',
                    title: 'NBCR Gas',
                    description: 'Incidenti che coinvolgono sostanze pericolose, chimiche o radioattive e ricerca o intercettazione di fughe di gas'
                },
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/calamita.png',
                    title: 'Calamità naturali',
                    description: 'Terremoti, frane e alluvioni.'
                },
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/incidente.png',
                    title: 'Incidenti',
                    description: 'Stradali e ferroviari.'
                },
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/esplosioni.png',
                    title: 'Esplosioni',
                    description: 'Scoppi o esplosioni.'
                },
                {
                    img: 'https://s3.eu-central-1.amazonaws.com/vfvmolinella/static/jobs/incendi.png',
                    title: 'Incendi',
                    description: 'Di varia tipologia (es. abitazioni, stabilimenti, sterpaglie, boschivi, canne fumarie, autovetture, ecc.).'
                }
            ]
        }
    }
}
</script>

<style lang="sass" scoped>
    @import "./../assets/global"
    .flex-container
        max-width: 80%
    .activity-card
        +flexbox(center, center, column, nowrap)
        padding: .3em 2em
        min-height: 14em
        max-width: 23em
        min-width: 23em
        margin: 3em
        text-align: center
        +breakpoint('<tablet')
            margin: 1em .5em
            min-width: 18em
            max-width: 18em
        +breakpoint('<small-phone')
            padding: .2em 0
            max-width: 16em
            min-width: 14em
        img
            height: 5em
            width: 5em
            margin: 0 auto
            +breakpoint('<tablet')
                height: 3em
                width: 3em
            +breakpoint('<small-phone')
                height: 5em
                width: 5em
</style>
